<template>
  <div>
    <Header class="d-lg-block d-none" />
    <HeaderBack class="d-lg-none d-block" />
    <DappsListFull :id="$route.params.id" />
    <Footer />
  </div>
</template>

<script>
import HeaderBack from "../components/HeaderBack.vue";
import Header from "../components/Header.vue";
import DappsListFull from "../components/DappsListFull.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "CategoryChoose",
  components: {
    HeaderBack,
    Header,
    DappsListFull,
    Footer,
  },
  created() {
    this.$mixpanel.track("Visit Category Choose");
  },
};
</script>

<style>
</style>