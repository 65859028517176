<template>
  <div>
    <!-- ===================================
          START THE BEST SELLER
      ==================================== -->
    <section class="margin-t-20 unList-bestSeller d-block d-lg-none">
      <!-- un-title-default -->
      <div class="un-title-default">
        <div class="text">
          <h2>
            List <span class="text-capitalize">{{ this.$props.id }}</span> dApps
          </h2>
          <p>
            Find best
            <span class="text-capitalize">{{ this.$props.id }}</span> dApps over
            the world
          </p>
        </div>
      </div>

      <div class="content-list-sellers">
        <ul class="nav">
          <li
            class="nav-item w-100"
            v-for="(dapps, index) in dappsList.data"
            :key="index"
          >
            <router-link
              :to="'/dapps-detail/' + dapps.slug"
              class="nav-link item-user-seller"
            >
              <div class="item-user-img">
                <picture>
                  <source
                    :srcset="
                      !dapps.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          dapps?.icon_url
                        : dapps?.icon_url
                    "
                    @error="() => (dapps.icon_url = '/images/no-images.png')"
                    type="image/webp"
                  />
                  <img
                    class="avt-img-small"
                    :src="
                      !dapps.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          dapps?.icon_url
                        : dapps?.icon_url
                    "
                    @error="() => (dapps.icon_url = '/images/no-images.png')"
                    alt=""
                  />
                </picture>
                <div class="txt-user">
                  <p>
                    {{ dapps.name }} <i class="ri-checkbox-circle-fill"></i>
                  </p>
                  <span
                    class="
                      text-dark
                      d-flex
                      flex-wrap
                      justify-content-start
                      align-items-center
                      gap-2
                    "
                  >
                    <span
                      v-for="(network, index) in dapps.networks"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img
                        :src="network.logo_url"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div class="other-option">
                <button type="button" class="btn btn-box-check">
                  <input type="checkbox" checked />
                  <div class="icon-box">
                    <i class="ri-add-fill"></i>
                  </div>
                </button>
              </div>
            </router-link>
          </li>
        </ul>
        <Pagination
          class="d-flex justify-content-center"
          :data="dappsList"
          :limit="2"
          :show-disabled="true"
          @pagination-change-page="getDappsByCategory"
        >
          <template #prev-nav>
            <span>&lt;</span>
          </template>
          <template #page-nav> </template>
          <template #next-nav>
            <span>&gt;</span>
          </template>
        </Pagination>
      </div>
    </section>

    <div class="container px-md-3 px-lg-2 px-0 d-lg-block d-none">
      <section class="margin-t-20 unList-bestSeller">
        <!-- un-title-default -->
        <div class="un-title-default">
          <div class="text">
            <h2>
              List
              <span class="text-capitalize">{{ this.$props.id }}</span> dApps
            </h2>
            <p>
              Find best
              <span class="text-capitalize">{{ this.$props.id }}</span> dApps
              over the world
            </p>
          </div>
        </div>

        <div class="content-list-sellers">
          <ul class="nav">
            <li
              class="nav-item w-100"
              v-for="(dapps, index) in dappsList.data"
              :key="index"
            >
              <router-link
                :to="'/dapps-detail/' + dapps.slug"
                class="nav-link item-user-seller"
              >
                <div class="item-user-img">
                  <picture>
                    <source
                      :srcset="
                        !dapps.icon_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                            dapps?.icon_url
                          : dapps?.icon_url
                      "
                      @error="() => (dapps.icon_url = '/images/no-images.png')"
                      type="image/webp"
                    />
                    <img
                      class="avt-img-small"
                      :src="
                        !dapps.icon_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                            dapps?.icon_url
                          : dapps?.icon_url
                      "
                      @error="() => (dapps.icon_url = '/images/no-images.png')"
                      alt=""
                    />
                  </picture>
                  <div class="txt-user">
                    <p>
                      {{ dapps.name }} <i class="ri-checkbox-circle-fill"></i>
                    </p>
                    <span
                      class="
                        text-dark
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                        gap-2
                      "
                    >
                      <span
                        v-for="(network, index) in dapps.networks"
                        :key="index"
                        class="d-inline-block"
                      >
                        <img
                          :src="network.logo_url"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div class="other-option">
                  <button type="button" class="btn btn-box-check">
                    <input type="checkbox" checked />
                    <div class="icon-box">
                      <i class="ri-add-fill"></i>
                    </div>
                  </button>
                </div>
              </router-link>
            </li>
          </ul>
          <Pagination
            class="d-flex justify-content-center"
            :data="dappsList"
            :limit="4"
            :show-disabled="true"
            @pagination-change-page="getDappsByCategory"
          >
            <template #prev-nav>
              <span>&lt;</span>
            </template>
            <template #page-nav> </template>
            <template #next-nav>
              <span>&gt;</span>
            </template>
          </Pagination>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "DappsListFull",
  props: ["id"],
  data() {
    return {
      ids: null,
      dappsList: {},
    };
  },
  mounted() {
    this.getDappsByCategory();
  },
  components: {
    Pagination: LaravelVuePagination,
  },
  methods: {
    async getDappsByCategory(page = 1) {
      let ids = this.$props.id;
      try {
        await axios
          .get(
            process.env.VUE_APP_API +
              "api/product/category/" +
              ids +
              "?page=" +
              page
          )
          .then((response) => {
            this.dappsList = response.data.data.products;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } catch (e) {
        console.log("Caught Error");
      }
    },
  },
};
</script>
<style>
.page-item.active .page-link .sr-only {
  display: none;
}
</style>